import React, { useRef } from 'react'
import { Link } from 'gatsby'
import { Container, Col, Row } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from 'gatsby-plugin-image'
import { LogoSmall, SubscribeBlock } from '/src/components/common'
import FooterMenu from '/src/components/navigation/FooterMenu'
import FooterProductMenu from '/src/components/navigation/FooterProductMenu'
import SocialList from '/src/components/social/SocialList'
import NearestStore from '/src/components/common/nearestStore'
import { LiveAgent } from '/src/components/chat'
import Header from './Header'

export default ({ hero, mainContainer, children }) => {
  const elementRef = useRef()

  return (
    <div ref={elementRef} className="app">
      <Header elementRef={elementRef} />
      {hero}

      <main>
        <Container {...mainContainer}>
          <Row className="d-flex justify-content-center">
            <Col xs={12}>
              {children}
            </Col>
          </Row>
        </Container>
        <div className="subscribe-block-wrapper pt-5 mt-5">
          <Container fluid="xxl">
            <Row className="d-flex align-items-center mb-5">
              <Col xs={12}>
                <SubscribeBlock />
              </Col>
            </Row>
          </Container>
        </div>
      </main>

      <footer>
        <Container fluid="xxl">
          <Row className="footer-top-row d-flex align-items-center mb-4">
            <Col sm={5} md={3}>
              <h3 className="m-0">
                <a className="phone-link" href="tel:1300306204">
                  <FontAwesomeIcon icon={faPhone} />1300 306 204
                </a>
              </h3>
            </Col>
            <Col sm={7} md={6}>
              <h3 className="m-0">
                <a className="email-link" href="mailto:sales@metalcorpsteel.com.au">
                  <FontAwesomeIcon icon={faEnvelope} />sales@metalcorpsteel.com.au
                </a>
              </h3>
            </Col>
            <Col sm={6} md={3}>
              <SocialList />
            </Col>
          </Row>
          <Row className="d-flex align-items-center">
            <Col sm={6} md={3}>
              <FooterProductMenu />
            </Col>
            <Col sm={6} md={3}>
              <FooterMenu />
            </Col>
            <Col sm={6} md={3}>
              <NearestStore />
            </Col>
            <Col sm={6} md={3}>
              <h6 className="mb-4 text-uppercase">Head Office</h6>
              <span>121 Evans Rd, <br/>Salisbury QLD 4107</span>
              <LogoSmall />
            </Col>
          </Row>
        </Container>
      </footer>

      <div className="footer-bottom">
        <Container fluid="xxl">
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <div className="footer-copyright">
                <span>
                  <StaticImage 
                    src="../../images/BlueScope-Blue-Metalcorp-is-a-BlueScope-Company.png" 
                    alt="Bluescope"
                    width={397}
                    height={41}
                    quality={100}
                    placeholder="blurred"
                    imgStyle={{ objectFit: 'contain' }}
                    layout="fixed" />
                </span>
                <ul className="list first">
                  <li>
                    <Link to="/terms-conditions">
                      Terms
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy-policy">
                      Privacy
                    </Link>
                  </li>
                </ul>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <ul className="list">
                <li>
                  <StaticImage 
                    src="../../images/paypal.svg" 
                    alt="Paypal"
                    width={72}
                    height={20}
                    placeholder="blurred"
                    layout="fixed" />
                </li>
                <li>
                  <StaticImage 
                    src="../../images/mastercard.svg" 
                    alt="Mastercard"
                    width={54}
                    height={42}
                    placeholder="blurred"
                    layout="fixed" />
                </li>
                <li>
                  <StaticImage 
                    src="../../images/visa.svg"
                    alt="Visa"
                    width={56}
                    height={20}
                    placeholder="blurred"
                    layout="fixed" />
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <LiveAgent />
    </div>
  )
}
